import React, { useMemo, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { reportFilterNumber } from 'components/modules/report/filter/fields/FilterNumber';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import {
  ACTION_COLUMN,
  DEFAULT_HIDE,
  DEFAULT_SHOW,
  ReportColumn,
  ReportFilter,
} from 'components/modules/report/report-types';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { inspectionReadingDuck } from 'ducks/inspection-reading/list';
import { ReportStateProps, useReportState } from 'hooks/use-report-state';
import { RouteComponentProps } from 'react-router-dom';
import { Model } from 'util/backendapi/models/api.interfaces';
import { EMPTY_FUNC } from 'util/misc';
import { formatDatetimeForDisplay, getCurrentDatetime } from 'util/dates';
import uniq from 'lodash/uniq';
import { i18n } from '@lingui/core';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { InlineTextEdit } from 'components/base/inline/InlineTextEdit';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';
import {
  Comment_RESOURCE_TYPE,
  Comment_TYPE,
  Media_CONTENT_TYPE,
  Media_FILE_TYPE,
  PerformanceIndicator_RESPONSE,
  User_PERMISSION,
} from 'util/backendapi/types/Enum';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'util/user';
import { FullState } from 'main/reducers';
import ActionBlock from 'components/base/actionblock/actionblock';
import {
  ExportPanelModalButton,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';
import { EditPerformanceIndicatorResponsesButton } from './EditPerformanceIndicatorsModal';
import {
  InspectionReportSortableMediaList,
  InspectionReportSortableMediaListProps,
} from './InspectionReportSortableMediaList';
import { Icon } from 'components/base/icon/icon';
import './InspectionReadingListScreen.scss';
import ButtonShowModal, {
  defaultModalClassName,
} from 'components/base/modal/buttonshowmodal';
import { UploadReadingMediaModal } from 'screens/media/preview/UploadReadingMediaModal';
import { HasPermission } from 'components/logic/has-permission/HasPermission';

interface InspectionListViewProps {
  reportProps: ReportStateProps<Model.ReportsInspectionReading>;
  routeMarchCode: string | null;
  editingCommentReadingId: number | null;
  canEditMediaCaption: boolean;
  canEditInspectionReport: boolean;
  setEditingCommentReadingId: (readingId: number | null) => void;
  onUpdateReadingInspectorComment: (
    readingId: number,
    content: string
  ) => Promise<void>;
  mediaLabels: Record<number, string>;
  onToggleExportMedia: InspectionReportSortableMediaListProps['onToggleExportMedia'];
  onUpdateMediaOrder: InspectionReportSortableMediaListProps['onUpdateMediaOrder'];
}

export const inspectionReadingFilters: ReportFilter[] = [
  reportFilterNumber('readings_batch', <Trans>Readings batch</Trans>),
];

export function inspectionReadingColumns({
  refreshList,
  modifyReportRow,
  editingCommentReadingId,
  canEditMediaCaption,
  canEditInspectionReport,
  setEditingCommentReadingId,
  onUpdateReadingInspectorComment,
  onToggleExportMedia,
  onUpdateMediaOrder,
  mediaLabels,
}: {
  refreshList: () => void;
  modifyReportRow: (
    id: number,
    partialRow: Partial<Model.ReportsInspectionReading>
  ) => void;
  editingCommentReadingId: number | null;
  canEditMediaCaption: boolean;
  canEditInspectionReport: boolean;
  setEditingCommentReadingId: InspectionListViewProps['setEditingCommentReadingId'];
  onUpdateReadingInspectorComment: InspectionListViewProps['onUpdateReadingInspectorComment'];
  onUpdateMediaOrder: InspectionListViewProps['onUpdateMediaOrder'];
  onToggleExportMedia: InspectionListViewProps['onToggleExportMedia'];
  mediaLabels: InspectionListViewProps['mediaLabels'];
}): ReportColumn<Model.ReportsInspectionReading>[] {
  return [
    {
      label: <Trans>Civil feature</Trans>,
      name: 'civil-feature',
      backendFieldName: 'observation_point__civil_feature__name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation-point-code',
      backendFieldName: 'observation_point__code',
      additionalFields: ['readings_batch__route_march__code'],
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Performance indicators</Trans>,
      name: 'performance-indicator-responses',
      backendFieldName: 'performance_indicator_responses',
      className: 'inspection-report-performance-indicators',
      additionalFields: ['observation_point__code', 'id'],
      visibility: DEFAULT_SHOW,
      accessor: ({
        performance_indicator_responses,
        observation_point__code: observationPointCode,
        id: readingId,
      }) => (
        <>
          {canEditInspectionReport &&
          performance_indicator_responses?.length > 0 ? (
            <EditPerformanceIndicatorResponsesButton
              readingId={readingId}
              performanceIndicatorResponses={performance_indicator_responses}
              observationPointCode={observationPointCode}
              onAfterSubmit={refreshList}
            />
          ) : null}
          {performance_indicator_responses?.map(
            (performance_indicator_response, idx) => {
              let response_value: JSX.Element;
              if (!performance_indicator_response.response) {
                response_value = <Trans>No response</Trans>;
              } else {
                response_value = (
                  <TransEnum
                    enum="PerformanceIndicator_RESPONSE"
                    value={performance_indicator_response.response}
                  />
                );
              }

              return (
                <span
                  className="inspection-report-performance-indicator-response"
                  key={idx}
                >
                  {performance_indicator_response.response ===
                  PerformanceIndicator_RESPONSE.OBSERVED ? (
                    <Icon
                      className="performance-indicator-response-icon-observed"
                      type="icon-exclamation-solid"
                    />
                  ) : performance_indicator_response.response ===
                    PerformanceIndicator_RESPONSE.ISSUE ? (
                    <Icon
                      className="performance-indicator-response-icon-issue"
                      type="icon-exclamation-solid"
                    />
                  ) : null}
                  {performance_indicator_response.performance_indicator.name}
                  {': '}
                  {response_value}
                  <br />
                </span>
              );
            }
          )}
        </>
      ),
    },
    {
      label: <Trans>Followup required</Trans>,
      name: 'followup-required',
      backendFieldName: 'followup_required',
      visibility: DEFAULT_SHOW,
      accessor: ({ followup_required }) =>
        followup_required ? <Trans>Yes</Trans> : <Trans>No</Trans>,
    },
    {
      label: <Trans>Inspection comment</Trans>,
      name: 'reading-inspector-comment',
      backendFieldName: 'reading_inspector_comment',
      className: 'inspection-report-inspection-comment',
      additionalFields: ['id'],
      visibility: DEFAULT_SHOW,
      accessor: ({ reading_inspector_comment, id }) =>
        canEditInspectionReport ? (
          <InlineTextEdit
            title={t`Edit comment`}
            content={reading_inspector_comment ?? ''}
            isEditing={id === editingCommentReadingId}
            setIsEditing={(editing) =>
              setEditingCommentReadingId(editing ? id : null)
            }
            onSubmit={async (content) => {
              await onUpdateReadingInspectorComment(id, content);
            }}
            onAfterSubmit={(content) => {
              modifyReportRow(id, { reading_inspector_comment: content });
            }}
          />
        ) : (
          reading_inspector_comment
        ),
    },
    {
      label: <Trans>Datetime</Trans>,
      name: 'reading-datetime',
      backendFieldName: 'reading_datetime',
      additionalFields: ['observation_point__site__area__time_zone__name'],
      visibility: DEFAULT_HIDE,
      accessor: ({
        reading_datetime,
        observation_point__site__area__time_zone__name: time_zone_name,
      }) => formatDatetimeForDisplay(reading_datetime, time_zone_name),
    },
    {
      label: <Trans>Media</Trans>,
      name: 'media',
      backendFieldName: 'media',
      visibility: DEFAULT_SHOW,
      className: 'preview-media',
      additionalFields: [
        'id',
        'observation_point__code',
        'observation_point__site__area__time_zone__name',
      ],
      accessor: ({
        id,
        media,
        observation_point__code,
        observation_point__site__area__time_zone__name: time_zone_name,
      }) => {
        if (!media) return;
        return (
          <InspectionReportSortableMediaList
            readingId={id}
            media={media}
            observationPointCode={observation_point__code}
            timeZone={time_zone_name}
            refreshList={refreshList}
            modifyReportRow={modifyReportRow}
            onToggleExportMedia={onToggleExportMedia}
            onUpdateMediaOrder={onUpdateMediaOrder}
            canEditInspectionReport={canEditInspectionReport}
            canEditMediaCaption={canEditMediaCaption}
            mediaLabels={mediaLabels}
          />
        );
      },
    },
    {
      ...ACTION_COLUMN,
      additionalFields: [
        'id',
        'observation_point__code',
        'observation_point__site__area__time_zone__name',
      ],
      accessor: ({
        id,
        observation_point__code,
        observation_point__site__area__time_zone__name: time_zone_name,
      }) => (
        <>
          <HasPermission check="can_create_media">
            <ButtonShowModal
              name="mediaPreviewModal"
              className="btn-link-panel"
              iconType="icon-upload"
              title={t`Upload media`}
              modalContent={(modalProps) => (
                <UploadReadingMediaModal
                  {...modalProps}
                  observationPointCode={observation_point__code}
                  readingId={id}
                  mediaContentType={Media_CONTENT_TYPE.ROUTE_MARCH_PHOTO}
                  onAfterSubmit={refreshList}
                />
              )}
              modalProps={{
                className: defaultModalClassName + ' panel-large',
                shouldCloseOnOverlayClick: false,
              }}
            ></ButtonShowModal>
          </HasPermission>
        </>
      ),
    },
  ];
}

async function updateInspectorCommentForReading(
  readingId: number,
  content: string
) {
  const reading = await getApi(`/readings/${readingId}/`);

  // If reading has an inspector comment, update the existing one
  if (reading.inspector_comment) {
    await patchApi(`/comments/${reading.inspector_comment.id}/`, {
      resourcetype: Comment_RESOURCE_TYPE.readingInspector,
      content: content,
    });
    // otherwise create a new reading inspector comment
  } else {
    const user = await getApi('/users/current/');
    await postApi(`/comments/`, {
      resourcetype: Comment_RESOURCE_TYPE.readingInspector,
      comment_type: Comment_TYPE.inspector,
      commenter: user.profile.name,
      created_datetime: getCurrentDatetime(),
      reading: readingId,
      content: content,
    });
  }
}

async function updateInspectorReportMedia(
  mediaId: number,
  exportMedia: boolean
) {
  await patchApi(`/reports/inspection-readings/media/${mediaId}/`, {
    export: exportMedia,
  });
}

async function updateInspectorReportMediaOrder(
  readingId: number,
  mediaIds: number[]
) {
  await patchApi(
    `/reports/inspection-readings/reading-media-order/${readingId}/`,
    {
      media_order: mediaIds,
    }
  );
}

function calculateMediaLabels(
  records: Model.ReportsInspectionReading[]
): Record<number, string> {
  let imageCount = 0;
  let videoCount = 0;
  let audioCount = 0;

  let mediaLabels: Record<number, string> = {};
  records.forEach((r) => {
    r.media.forEach((m) => {
      let prefix = '?';
      let suffix = '-';

      if (m.file_type === Media_FILE_TYPE.IMAGE) {
        prefix = i18n._(t`Photo`);
      } else if (m.file_type === Media_FILE_TYPE.VIDEO) {
        prefix = i18n._(t`Video`);
      } else if (m.file_type === Media_FILE_TYPE.AUDIO) {
        prefix = i18n._(t`Audio`);
      }

      if (m.file_type === Media_FILE_TYPE.IMAGE) {
        // Images should only have a number when export is ticked
        if (m.export === null || m.export) {
          suffix = String(++imageCount);
        }
      } else if (m.file_type === Media_FILE_TYPE.VIDEO) {
        suffix = String(++videoCount);
      } else if (m.file_type === Media_FILE_TYPE.AUDIO) {
        suffix = String(++audioCount);
      }

      mediaLabels[m.id] = `${prefix} ${suffix}`;
    });
  });
  return mediaLabels;
}

export function InspectionReadingListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    inspectionReadingColumns({
      refreshList: EMPTY_FUNC,
      modifyReportRow: EMPTY_FUNC,
      editingCommentReadingId: null,
      canEditMediaCaption: false,
      canEditInspectionReport: false,
      setEditingCommentReadingId: EMPTY_FUNC,
      onUpdateReadingInspectorComment: EMPTY_FUNC,
      onToggleExportMedia: EMPTY_FUNC,
      onUpdateMediaOrder: EMPTY_FUNC,
      mediaLabels: {},
    }),
    inspectionReadingFilters,
    inspectionReadingDuck,
    (state) => state.inspectionReading.list
  );

  const mediaLabels = useMemo(() => {
    return calculateMediaLabels(reportState.records);
  }, [reportState.records]);

  const routeMarchCodes = uniq(
    reportState.records.map((r) => r.readings_batch__route_march__code)
  );

  const [editingCommentReadingId, setEditingCommentReadingId] = useState<
    number | null
  >(null);

  const { canEditInspectionReport, canEditMediaCaption } = useSelector(
    (state: FullState) => {
      const canEditInspectionReport = selectHasPermission(
        state,
        User_PERMISSION.can_edit_inspection_report
      );
      const canEditMediaCaption = selectHasPermission(
        state,
        User_PERMISSION.can_edit_media
      );
      return { canEditInspectionReport, canEditMediaCaption };
    }
  );

  return (
    <InspectionReadingListView
      reportProps={reportState}
      mediaLabels={mediaLabels}
      routeMarchCode={routeMarchCodes.length === 1 ? routeMarchCodes[0] : null}
      onUpdateReadingInspectorComment={updateInspectorCommentForReading}
      onToggleExportMedia={updateInspectorReportMedia}
      onUpdateMediaOrder={updateInspectorReportMediaOrder}
      editingCommentReadingId={editingCommentReadingId}
      setEditingCommentReadingId={setEditingCommentReadingId}
      canEditMediaCaption={canEditMediaCaption}
      canEditInspectionReport={canEditInspectionReport}
    />
  );
}

const getExportUrl: GetExportUrlFunc = (
  exportFormat,
  { queryParams },
  i18n,
  generatedDatetime,
  pageOrientation
) => {
  return {
    path: `/export/${exportFormat}/reports/inspection-readings/`,
    queryParams: queryParams,
  };
};

export const InspectionReadingListView: React.FunctionComponent<InspectionListViewProps> =
  function (props) {
    const {
      reportProps,
      editingCommentReadingId,
      canEditMediaCaption,
      canEditInspectionReport,
      setEditingCommentReadingId,
      onUpdateReadingInspectorComment,
      onToggleExportMedia,
      onUpdateMediaOrder,
      routeMarchCode,
      mediaLabels,
    } = props;
    const columns = inspectionReadingColumns({
      refreshList: reportProps.refreshList,
      modifyReportRow: reportProps.modifyReportRow!,
      editingCommentReadingId,
      canEditMediaCaption,
      canEditInspectionReport,
      setEditingCommentReadingId,
      onUpdateReadingInspectorComment,
      onToggleExportMedia,
      onUpdateMediaOrder,
      mediaLabels,
    });

    const filters = inspectionReadingFilters;
    return (
      <PageStandard
        name="inspection-report"
        header={
          routeMarchCode ? (
            <Trans>Inspection report: {routeMarchCode}</Trans>
          ) : (
            <Trans>Inspection report</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersToShow={[]}
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="filtered-table-options">
            <ExportPanelModalButton
              canExportDocx={true}
              taskQueueFormats={[ExportFormats.DOCX]}
              getExportUrl={getExportUrl}
              canSelectPageOrientation={false}
            />
          </ActionBlock>
          <ReportTable<Model.ReportsInspectionReading>
            {...reportProps}
            columns={columns}
            // Don't pass filters as we don't want to display 'filtered to' message
            // for the readings batch filter
            filters={[]}
          />
        </div>
      </PageStandard>
    );
  };
